import React, { FC } from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { SectionHeader } from '../../common/SectionHeader';
import { classes, st } from './AccountSettings.st.css';
import { Accordion } from '../../common/Accordion';
import {
  BlockedMembers,
  CommunityPageUrl,
  ProfileVisibility,
} from './Settings';
import {
  Experiment,
  MemberRole,
  PageAlignment,
  ProfilePrivacyStatus,
} from '../../../../../types';
import { useMember, useSiteContext } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';
import { DataHook } from '../../../../../constants/DataHook';

const isPublicProfile = (privacyStatus: ProfilePrivacyStatus) => {
  return privacyStatus === ProfilePrivacyStatus.PUBLIC;
};

const isOwner = (role: MemberRole | undefined) => role === MemberRole.OWNER;

export const isAccountSettingEmpty = (
  privacyStatus: ProfilePrivacyStatus,
  role: MemberRole | undefined,
  isCustomProfileEnabled: boolean,
  shouldShowBlockedMembers: boolean,
) => {
  return (
    !isPublicProfile(privacyStatus) &&
    (isOwner(role) || !isCustomProfileEnabled) &&
    (isOwner(role) || !shouldShowBlockedMembers)
  );
};

export const AccountSettings: FC = () => {
  const { t } = useTranslation();
  const { privacyStatus, role } = useMember();
  const { isCustomProfileEnabled } = useSiteContext();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const shouldShowBlockedMembers = experiments.enabled(
    Experiment.MemberBlockMember,
  );

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;

  return (
    <div
      data-hook={DataHook.MyAccountSettings}
      className={st(classes.root, {
        alignCenter: pageAlignment === PageAlignment.center,
      })}
    >
      <SectionHeader
        title={t('app.widget.sections.account-settings.title')}
        subtitle={t('app.widget.sections.account-settings.subtitle')}
        className={classes.sectionHeader}
      />
      <Accordion>
        {isPublicProfile(privacyStatus) && <CommunityPageUrl />}
        {!isOwner(role) && isCustomProfileEnabled && <ProfileVisibility />}
        {!isOwner(role) && shouldShowBlockedMembers && <BlockedMembers />}
      </Accordion>
    </div>
  );
};
